.detection-feedback {
  position: absolute; /* Use absolute positioning inside the container */
  top: 10px;  /* Align it to the bottom of its container */
  right: 50%;
  transform: translateX(-50%);
  width: 300px;
  max-width: 90%;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  z-index: 900;
}

.detection-feedback.collapsed {
  transform: translateY(100%);
}

.feedback-toggle {
  background: #4caf50;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.feedback-content {
  padding: 15px;
}

.feedback-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #333;
}

.feedback-header .close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.rating-section, .notes-section, .submit-section {
  margin-top: 10px;
}

.submit-feedback-button {
  width: 100%;
  padding: 8px;
  background: #4caf50;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-feedback-button:disabled {
  background: #ccc;
}

@media screen and (max-width: 768px) {
  .feedback-toggle {
    margin-left: 20px; /* Adjust left margin on mobile */
  }

  .detection-feedback.mobile-drawer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    max-height: 50%;
    border-radius: 0;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.2);
    visibility: hidden;
  }

}
