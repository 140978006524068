/* Contact Section Container */
.contact-container {
    background-color: #f8f9fb;
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-direction: column; /* Stack children vertically */
  }
  
  /* Grid Layout for Larger Screens */
  .contact-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    align-items: start;
    width: 100%;
    max-width: 1000px; /* Limit the max width */
  }
  
  /* Contact Form Styling */
  .contact-form {
    padding: 30px;
    background: white;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%; /* Ensure full width on smaller screens */
  }
  
  .contact-form .form-label {
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .contact-form button {
    background-color: #4caf50;
    border-color: #4caf50;
    color: white;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 8px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #45a049;
    border-color: #45a049;
  }
  
  /* Help Options Section */
  .help-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .help-card {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
  }
  
  .help-card i {
    font-size: 2rem;
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  .help-card h4 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }

  .contactIcon {
    font-size: 2rem;
    /* margin-bottom: 10px; */
    color: #4caf50;
  }

  .faq-button {
    background-color: #4caf50; /* Initial button color */
    color: #fff; /* White text color */
    padding: 10px 20px; /* Padding inside the button */
    font-size: .8rem; /* Slightly larger text */
    font-weight: bold;
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition */
  }
  
  .faq-button:hover {
    background-color: #388e3c; /* Darker green on hover */
    color: #ffffff; /* Ensure text remains white */
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow effect */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-container {
      padding: 30px 15px; /* Adjust padding for smaller screens */
    }
  
    .contact-grid {
      grid-template-columns: 1fr; /* Single-column layout */
      gap: 20px;
    }
  
    .help-options {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
    }
  
    .help-card {
      flex: 1 1 calc(50% - 10px); /* 50% width for help cards with a gap */
    }
  }
  