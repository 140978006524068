/* MenuComponent.css */
.menu-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    justify-content: center;
  }
  
  
  .menu-container.fixed {
    position: fixed; /* Fixed at the bottom of the screen */
  }
  
  button {
    padding: 10px;
    font-size: 16px;
  }
  