

#overlay {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    width: 100%; /* Or set to the width of the canvas */
  }
    
h3{
  margin-top: 0px;
  margin-bottom: 0px;

}


/* .btn{
  padding: 0px;
} */
  .calibrationContainer {
    /* display: flex; */
    align-items: center; /* Vertically aligns the child elements */
    justify-content: center; /* Horizontally aligns the child elements */
    /* position: absolute;
    top: 0;
    left: calc(100% - 300px); */
    z-index: 2;
    background-color: rgba(220, 228, 222, 0.7); /* Light background with slight transparency */
    border: 1px solid #ccc; /* Optional */
    border-radius: 5px; /* Optional */
    padding: 1em;
    margin-left: 1.8em;
    margin-right: 1em;
  }
  
  /* Style for slider thumb (button) */
input[type=range]::-webkit-slider-thumb {
    background-color: #4caf50; /* Primary color */
    cursor: pointer;
    border: none;
  }
  
  input[type=range]::-moz-range-thumb {
    background-color: #4caf50; /* Primary color */
    cursor: pointer;
    border: none;
  }

  #overlay-title {
    font-size: 18px;
    margin-right: 20px;
  }
  
  /* .slider {
    width: 100px;
    background: rgba(220, 228, 222, 0.7);
    -webkit-appearance: none;
    height: 8px;
    border-radius: 4px;
    outline: none;
    margin: 0 10px;
  } */
  
  /* .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  } */
  
  #brush-width-value {
    margin: 0 10px;
  }
 
  #reset-button, #finish-button, #deselect-button {
    margin: 0 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #reset-button:hover, #finish-button:hover {
    background-color: #0056b3;
  }
  
  /* Add this to your existing CSS file */


.dimension-display {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #4caf50;
  padding: 5px 10px;
  border-radius: 3px;
  z-index: 0; /* You can adjust this value to ensure the display appears above the canvas */
}
