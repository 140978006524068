/* Base styles for mobile-first design */
/* If you have base styles that apply for all devices, place them here */

/* Smartphones in Portrait Mode */

.zoom-buttons-mobile {
    display: none;
}

.secondNavContainer .navbar::-webkit-scrollbar {
    display: none;
}

.canvasContainer.showCalibrationOverlay {
    margin-left: 30px;
}

.new-div-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* if you want the buttons centered */
    align-items: center;
    /* if you want vertical alignment */
    gap: 10px;
    /* space between the buttons */
}

.custom-width {
    max-width: 90px;
    /* Adjust this value as needed */
    width: 100%;
    /* Ensures responsiveness */
}

.sketch-picker-container .sketch-picker {
    width: 100px;
    /* Existing width */
}

@media (max-width: 576px) {
    
    .actionBtn {
        padding: 0.5rem;
        margin: 0.3rem;
        font-size: 12px; /* Smaller font for action buttons */
    }

    .zoom-buttons-mobile .undoRedoSmall {
        padding: 0.5rem;
        gap: 0.5rem;
    }
    

    .siteNavBar .navbar-nav {
        display: flex;
        flex-direction: column; /* Stack the items */
    }

    .navbar-toggler {
        margin-left: auto;
        /* Push the hamburger icon to the right */
    }

    .navbar-nav .nav-link {
        padding: 0.5rem 1rem;
        text-align: center;
    }

    /* .guest-mode-title {
        font-size: 3vw; 
        padding: 1rem; 
    } */

    .ai-Detect-button-AI {
        font-size: 2vw;
        padding: 0.5rem;
    }

    .btn {
        font-size: 2.5vw; /* Responsive font size */
        padding: 1rem;
    }
}

/* Media query for very small screens (smartphones, smaller than 480px) */
@media (max-width: 480px) {
    .guest-mode-title {
      padding: 6px 12px; /* Even smaller padding */
      font-size: 12px;   /* Smaller font size for small screens */
      top: 5px;          /* Reduce top position for smaller screens */
    }
  }

@media screen and (max-width: 768px){

    variation-btn {
        top: 15px;
        left: 15px;
        width: 35px;
        height: 35px;
    }

    .secondNavContainer .navbar {
        font-size: 12px; /* Reduce font size */
        padding: 5px 10px;
    }
    
    .secondNavContainer .nav-link {
        padding: 4px 6px;
    }

    .button-container {
        flex-direction: column;  /* Stack buttons vertically on smaller screens */
        align-items: center;
    }
    .items button {
        width: 40px;
        height: 40px;
    }

    .dropdown-menu {
        overflow-y: scroll !important; /* Ensures vertical scroll */
        -webkit-overflow-scrolling: touch !important;
        height: auto; /* Ensure it doesn’t collapse */
        min-width: 180px !important; /* Reduce width */
        max-height: 40vh !important; /* Limit height */
        padding: 6px !important; /* Reduce padding */
        font-size: 14px !important; /* Smaller font */
    }

    .dropdownTitleSection {
        font-size: 14px; /* Reduce section title size */
        padding: 6px; /* Less padding */
    }

    .itemTypeTitle {
        font-size: 13px; /* Make items smaller */
        padding: 5px 8px; /* Reduce padding for items */
    }

    .addItemsIcons {
        height: 15px !important; /* Make icons smaller */
        margin-left: 5px;
    }


    .ai-Detect-button-AI {
        font-size: xx-small; /* Reduce text size */
        padding: 5px 10px; /* Smaller padding for both width and height */
        width: auto; /* Auto-adjust width to fit content */
        height: auto; /* Auto-adjust height to fit content */
        border-radius: 4px; /* Optional: maintain a rounded shape */
    }
    
      .ai-Measurement-button {
        position: absolute;
        color: #fff;
         top: 10%;
        right: 5px;
        font-size: xx-small;
        /* padding-left: 5px; */
      }

    .sketch-picker-container .sketch-picker {
        width: 90vw;
        /* or a specific larger pixel value */
    }

    .form-button-icon {
        display: inline;
        /* Show icon on small screens */
    }

    .form-button-text {
        display: none;
        /* Hide text on small screens */
    }

    .designer-text {
        display: none;
    }

    .designer-icon {
        width: 35px;
        padding-left: 4px;
    }

    .custom-width {
        width: 100%;
        /* Ensures responsiveness */
        font-size: x-small;

    }

    /* .bannerContainer {
        display: none;
    } */

    .sticky-banner {
        position: relative; /* Repositioned on larger screens */
    }

    .extraSmallButton {
        width: 95%;
        font-size: 8px;
        text-align: center;
    }

    .canvasContainer {
        overflow-y: auto;
        display: flex;
        position: relative;
        max-height: 2400px;
        /* margin-right: 14%; */
      }


    .canvasContainer.showCalibrationOverlay {
        margin-left: 7px;
    }

    #known-length {
        width: 50%;
    }

    .calibration span {
        display: block;
        margin-bottom: 8px;
        /* optional, adjust spacing between elements */
    }

    .buttonDone {

        margin-right: 8px;
    }

    .toolTitle {
        margin-left: 8px;
    }

    #overlay {
        padding: 5px;

        /* Adjust padding or other layout properties if needed */
    }

    .dimension-display {
        font-size: 10px;
        padding: 3px 5px;
    }

    .btn-group-vertical {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .btn-group-vertical .btn {
        width: 100%;
        margin-bottom: 5px;
    }

    .btn img {
        height: 30px;
        /* Reduce the size */
    }

    .btn-group-toggle .btn img {
        height: 30px;
        /* Reduced from 40px */
    }

    /* For unit toggle buttons */
    .btn-group-toggle .btn {
        padding: 3px 6px;
        font-size: 10px;
    }


    .eraserDrawContainer,
    .calibrationContainer {
        padding: 8px;
        margin-left: 6px;
        margin-right: 6px;
        margin-bottom: 3px;

    }
    .guestContainer {
        padding: 8px;
        margin-left: 6px;
        margin-right: 6px;
        margin-bottom: 3px;

    }
    button.before-after-button {
        padding: 3px 5px;
        font-size: 10px;
    }

    .before-after-label {
        display: none;
    }

    #overlay h3 {
        font-size: 14px;
        padding-left: 4px;
        /* Adjust the font size for the title */
    }

    #overlay label,
    #overlay span {
        font-size: 10px;
        /* Font size for labels and spans */
    }

    #overlay button {
        width: 40px;
        font-size: 10px;
        /* Adjust the button font size */
        padding: 3px;
        /* Adjust the button padding */
    }

    /* #overlay .slider {
        
        width: 50px;
        height: 6px;
        border-radius: 3px;
        margin: 0 8px;
    } */

    .zoom-buttons-mobile {
        display: block;
    }

    .slogan {
        font-size: medium;
    }


    .siteNavBar .navbar-toggler {
        margin-left: auto;
        /* This will push the button to the right */
    }

    /* Hide the original zoom buttons section on small screens */
    .zoom-buttons {
        display: none;
    }

    .zoom-buttons-mobile {
        /* Your specific styles for small screens */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    .zoom-buttons-mobile .undoRedoSmall {
        flex-direction: row;
        gap: 10px;
    }

    .zoom-buttons-mobile .slidersContainer>div {
        flex-direction: row;
        gap: 10px;
    }

    .btn.btn-secondary.btn-sm {
        width: 60px;
    }

    .btn {
        font-size: 12px;
        padding: 5px 10px;
        margin-bottom: 10px;
    }

    .items {
        flex-wrap: wrap;
        padding: 0px;
        width: 18%;
    }

    .startProject {
        display: none;
        padding-right: 10px;
    }

    .itemGroup,
    .actionGroup {
        margin-bottom: 0px;
        max-width: 80px;
    }

    .optionsSelectionView,
    .itemSelectionView {
        max-height: 300px;
        max-width: 60%;
        z-index: 2;
    }

    .topBar {
        padding: 6px;
        font-size: small;
        margin: 2px;
    }

    .selectionTitle {
        padding-left: 1px;
        font-size: small;
    }

    select {
        font-size: 11px !important;
        padding: 4px 6px !important;
    }

    .dropdownSection {
        width: auto;
        font-size: 12px;
        /* padding-top: 3px; */
        padding-bottom: 7px;
    }


    .optionsTitle,
    .noGrid {
        padding-left: 1px;
        font-size: small;
    }

    /* .undoRedo {
        display: none;
    } */
    /* .topBar .undoRedoSmall {
        display: block;
        padding-top: 10px;
    } */

    /* .openProject{
        display: none;
    } */

    .button-text,
    .button-tooltip {
        display: none;
    }

    .mt-1 {
        margin-top: 0px !important;
    }

    .items .btn {
        padding: 3px 5px;
        /* Adjust padding to reduce button size */
        font-size: 12px;
        /* Adjust font size if you want the text to be smaller */
    }

    .addItemsButton,
    .colorButton,
    .optionsButton,
    .shapesButton,
    .toolsButton,
    .selectAllButton,
    .removeButton {
        height: 30px;
        /* Adjust the height of the image icon to make it smaller */
        width: auto;
    }
}

@media (min-width: 820px) {

  }
  

/* Smartphones in Landscape Mode */
@media screen and (max-width: 926px) and (orientation: landscape) {
    /* Styles for landscape smartphones */
    /* Place specific styles for landscape smartphones here. */

    /* ... Existing rules ... */
    .siteNavBar .navbar-toggler {
        margin-left: auto;
        /* This will push the button to the right */
    }

    .selectionView {

        left: 17%;

    }

}

/* iPad in Portrait Mode */


/* iPad in Landscape Mode */
@media screen and (min-width: 810px) and (max-width: 1080x) and (orientation: landscape) {
    /* Styles for landscape iPads */

    /* ... Existing rules ... */

}

/* iPad Pro in Portrait Mode */
/* @media screen and (min-width: 1080px) and (max-width: 1366px) and (orientation: portrait) {
    .mainContainer {
        flex-flow: column;
    }
    .mainContainerLeft {
        width: 100%;
    }
    .beforeAfter {
        width: 100%;
        padding-left: 10px;
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .beforeEditMain {
        width: 50%;
        float: left;
        padding-right: 5px;
    }
    .afterEditMain {
        width: 50%;
        float: left;
        padding-left: 5px;
    }
    .downloadFormat {
        text-align: center;
        display: block;
        clear: both;
    }
    .selectionView {
        display: flex;
        flex-direction: row;
        position: absolute;
        z-index: 100;
        left: 174px;
    }
    .actionBtn svg {
        width: 12px;
    }
    .canvasContainerButtons .zoomout-wrap {
        display: flex;
        clear: both;
    }

} */

/* iPad pro in Landscape Mode */
@media screen and (min-width: 1080px) and (max-width: 1366px) and (orientation: landscape) {
    /* Styles for landscape iPads */

    /* ... Existing rules ... */

}