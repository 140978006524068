.account-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px; /* Adjust as needed */
    padding: 20px;
    background: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    z-index: 1100;
  }
  
  .account-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }
  .link {
    margin-right: 10px; /* Space between links */
    font-size: 14px; /* Smaller font size for links */
  }
  
  .link:last-child {
    margin-right: 0; /* No margin on the last link */
  }
  
  .button {
    margin-right: 10px; /* Space between buttons */
    margin-top: 10px;
  }
  
  .button:last-child {
    margin-right: 0; /* No margin on the last button */
  }
  
  .buttons-container {
    margin-top: 20px; /* Space above the button container */
  }

  .logout-button {
    margin-right: 20px; /* Space above the button container */
  }
  
  