/* CustomVideoModal.css */
.modal-body {
    padding: 20px;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 70vh;
}

.video-container video {
    width: 100%;
    height: auto;
    max-height: 100%;
}

.thumbnails {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.thumbnail-item {
    text-align: center;
    margin: 0 10px;
    cursor: pointer;
}

.thumbnail-item img {
    width: 100px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.thumbnail-item p {
    margin-top: 5px;
    font-size: 14px;
}

/* Feature Section Layout */
.feature-section-container {
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
  
  .feature-header {
    margin-bottom: 20px;
  }
  
  .feature-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  /* Media Styling */
  .feature-media {
    flex: 1;
    min-width: 300px;
  }
  
  .feature-media .feature-video,
  .feature-media .feature-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Description Styling */
  .feature-descriptions {
    flex: 1;
    min-width: 300px;
    text-align: left;
  }
  
  .feature-step {
    margin-bottom: 20px;
  }
  
  .step-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  .step-description {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }
  
  .step-link {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .step-link:hover {
    color: #0056b3;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .feature-content {
      flex-direction: column;
      align-items: center;
    }
  
    .feature-media,
    .feature-descriptions {
      width: 100%;
    }
  }
  