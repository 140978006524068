.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0,0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
  top: 0;
  left: 0;
}

.modalContainer {
  width: 400px;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.uploadProgress {
  width: 200px;
  background-color: dimgray;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
}

.list {
  background-color: #0275d8;
  padding-bottom: 100px;
margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.img{

  width: 200px;
  margin-left: 21%;

}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}