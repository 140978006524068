.feature-image {
    max-width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
  }
/* FeatureSection.css */

/* FeatureSection.css */

/* .how-it-works-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
} */

.section-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  color: #4caf50;
}
.cta-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 20px; /* Add spacing from other content */
  width: 100%; /* Full width for alignment */
}

.cta-button {
  background-color: #4caf50; /* Initial button color */
  color: #fff; /* White text color */
  padding: 10px 20px; /* Padding inside the button */
  font-size: 1.2rem; /* Slightly larger text */
  font-weight: bold;
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition */
}

.cta-button:hover {
  background-color: #388e3c; /* Darker green on hover */
  color: #ffffff; /* Ensure text remains white */
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow effect */
}


.steps-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.step {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center content horizontally */
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center; /* Ensure text aligns properly */
}

.step-icon {
  font-size: 1.5em;
  margin-right: 15px;
  color: #007bff;
}

.step-content {
  flex: 1;
}

.step-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.step-description {
  font-size: 1em;
  color: #555;
}

.step-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.feature-video {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (min-width: 600px) {
  .steps-container {
      grid-template-columns: 1fr 1fr;
  }
}



  