.modalBackground {
  /*width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;*/

  position: absolute;
  top: 0px;
  left: 0px;    
  display: flex;
  justify-content: center;
  background-color: rgba(200, 200, 200);
  align-items: center;
  z-index: 1010;
  top: 0;
  left: 0;
}

.itemModalContainer {
  width: auto;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow: auto;
  z-index: 1000;
  
}
.itemModalHeader {
display: flex;
flex-flow: row-reverse;
justify-content: space-between;
align-items: center;
}

.uploadProgress {
  width: auto;
}

.itemModalContainer .itemTitle {
  display: inline-block;
  text-align: center;
}

.itemList {
  background-color: #0275d8;
padding-bottom: 100px;
margin-top: 10px;

}

.itemTitleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.itemTitleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
line-height: 1;
}

.itemModalContainer .itemBody {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.img{

  height: 100px;
  margin-left: 21%;

}

/* .dropdown{
  height: 100px;
  overflow-y: auto;  
} */
.itemModalContainer .itemFooter {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemModalContainer .itemFooter button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}