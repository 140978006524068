/* Gallery.css */
.gallery-header {
    background-color: #000; /* Black background */
    color: white; /* White text */
    text-align: center; /* Center align the text */
    padding: 20px; /* Padding for spacing */
    border-bottom: 2px solid white; /* Bottom border for emphasis */
  }
  
  .gallery-header h2 {
    margin: 0; /* Removes default margin */
    font-size: 24px; /* Adjust font size as needed */
  }
  
  .carousel-item img {
    border: 2px solid white !important; /* Adds a white border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6) !important; /* Adds a subtle shadow */
    max-height: 120px !important; /* Adjust the height as needed */
    width: auto !important; /* Maintain the aspect ratio */
    margin: 0 auto !important; /* Center the image */
    display: block !important; /* Ensure the image is a block element */
    height: 100% !important; /* Ensure the image takes up the full height */
  }
  
  .carousel-caption {
    /* background: rgba(0, 0, 0, 0.5) !important; */
    padding: 10px !important;
    font-size: 14px !important;
    color: black;
    /* bottom: 5px !important; */
  }
  