.account-modal {
  max-width: 90vw;
}

@media (min-width: 768px) {
  .account-modal {
    max-width: 500px;
  }
}

.projectImageContainer {
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* This makes it a square container */
  position: relative;
}

.currentProject h2 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #4caf50; /* Darker text for contrast */
}

.projectImage {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%; */
  height: 100%;
  object-fit: contain; /* This makes sure the image fits without cropping */
  max-width: 100%;
  border-radius: 5px;
  transition: transform 0.3s;
}

.projectImage:hover {
  transform: scale(1.05);
}


.profile-label {
  cursor: pointer;
}

.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
  overflow: auto;
}

.sticky-close-button .modal-header {
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Optional: Change the background color as needed */
  z-index: 9999; /* Add a high z-index value */
  padding: 15px; /* Add padding to the header for better spacing */
}

.sticky-close-button .btn-close {
  font-size: 20px; /* Adjust font size if needed */
  color: #000; /* Add a desired color for the close button */
  background: transparent; /* Optional: Set the background to transparent to make the button blend with the header */
  border: none; /* Optional: Remove the border if not required */
  cursor: pointer;
}

.custom-modal .modal-header {
  position: sticky;
  top: auto;
  background-color: #f8f9fa; /* Optional: Change the background color as needed */
  z-index: 9999; /* Add a high z-index value */
}



.accountModalContainer {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow: auto;
  align-items: center;
  border-radius: 12px;
  position: relative;
}

.accountModalContainer .title {
  margin-top: 5px;
  text-align: center;
}

.accountModalContainer .currentProject {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 10px;
}

.enterName .buttonEnterName {
  text-align: center;
  margin-top: 10px;
}

.enterName {
  text-align: center;
  margin-bottom: 20px;
}

.listButttons {
  font-size: 1rem;
  cursor: pointer;
  padding-bottom: 0.5rem;
  display: grid;
    grid-template-columns: 1fr 1fr;  /* two columns layout */
    gap: 6px;  /* spacing between the buttons */
    margin-top: 15px;
}

/* .listButttons button {
  margin: 5px;
  padding: 3px;
} */

.projectDivider {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.4); /* Change the color as needed */
  margin: 10px 0; /* Add spacing above and below the divider */
  grid-column: span 2; 
}

.editName, .deleteProject {
  width: 100%;  /* makes the button take full width of its container (half the space since we have 2 columns) */
  padding: 10px;  /* gives the button a nice size */
  display: flex;  /* used for aligning icon and text */
  justify-content: center;  /* centers content horizontally */
  align-items: center;  /* centers content vertically */
}

.deleteProject, .openProject {
  grid-column: span 2;  /* makes the delete button take full width (spanning two columns) */
}

.accountModalContainer .body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.projectDetails {
  background-color: rgba(200, 200, 200, 0.3);
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
}

.projectTitle {
  background-color: blue;
  padding: 10px;
  color: white;
  margin-bottom: 10px;
  border-radius: 4px;
}

.projectDetails ul {
  padding-left: 0 !important;
}


hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ffff00;
  margin-top: 20px;
  margin-bottom: 20px;
}

ul {
  padding-left: 0;
  list-style: none;
}

.modalContainer .footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

.modal-header {
  display: flex;
  justify-content: space-between; /* To push the close button to the right */
  align-items: center;
  border-bottom: none;
  position: relative; /* Add relative positioning to the header */
  max-width: 100%; /* Set the maximum width of the header to 100% */
}
/* styles.css (or any other CSS file you are using) */
.custom-modal .modal-header {
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Optional: Change the background color as needed */
  z-index: 9999; /* Add a high z-index value */
}

.modal-header {
  display: flex;
  justify-content: space-between; /* To push the close button to the right */
  align-items: center;
  border-bottom: none;
  position: relative; /* Add relative positioning to the header */
}

.title {
  text-align: center;
  color: black;
  padding: 0 30px; /* Add padding to the title to create space around it */
}

.custom-modal {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #F3F4F6;
}

.profile-section, .currentProject {
  padding: 20px;
  margin-top: 10px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-title {
  font-weight: bold;
  margin-right: 10px;
}

.profile-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Adjust the styling of the close button */
.custom-modal .close {
  font-size: 20px; /* Adjust font size if needed */
  color: #000; /* Add a desired color for the close button */
  cursor: pointer;
  position: absolute;
  top: 10px; /* Adjust top position as needed */
  right: 10px; /* Adjust right position as needed */
  z-index: 10000; /* Add a high z-index value to ensure it's always on top */
  background: transparent; /* Optional: Set the background to transparent to make the button blend with the header */
  border: none; /* Optional: Remove the border if not required */
}

.modal-header .title {
  flex-grow: 1;
  text-align: center;
  color: black;
}
.title {
  text-align: center;
  color: black;
  padding: 0 30px; /* Add padding to the title to create space around it */
}

.close-btn-container {
  padding: 10px;
  color: #000;
}

.modal-header .close-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -15px;
  margin-right: -15px;
  flex-grow: 1;
}

.modal-header .btn-close {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}
