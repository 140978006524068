/* 📌 Generate Variations Button (Top-Left) */
.generate-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #4caf50;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    /* z-index: 2000; */
}

.generate-btn:hover {
    background: #0056b3;
}

/* 📌 Drawer Container */
.variation-drawer {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    transition: left 0.3s ease-in-out;
    z-index: 1500;
}

/* 📌 Open Drawer */
.variation-drawer.open {
    left: 0;
}

/* 📌 Drawer Header - Make it Sticky */
.drawer-header {
    position: sticky;
    top: 0;
    z-index: 10; /* Ensure it stays above scrolling content */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #007bff;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Optional separator */
}


/* 📌 Close Button */
.close-btn {
    background: transparent;
    color: white;
    font-size: 18px;
    border: none;
    cursor: pointer;
}

/* 📌 Variation List */
.variation-list {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* 📌 Variation Item */
.variation-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* 📌 Variation Thumbnail */
.variation-thumbnail {
    width: 90%;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s;
}

.variation-thumbnail:hover {
    transform: scale(1.1);
}

.variation-thumbnail.selected {
    border: 4px solid #007bff;
}
.variation-drawer {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 250px;
    background: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    z-index: 2000;
}

.variation-drawer.closed {
    transform: translateX(-100%);
}

.toggle-drawer-btn {
    position: fixed;
    left: 250px;
    top: 50%;
    transform: translateY(-50%);
    background: #4caf50;
    color: white;
    border: none;
    border-radius: 10px;
    width: 30px;
    height: 60px;
    cursor: pointer;
    transition: left 0.3s ease-in-out;
    z-index: 2001;
}

.toggle-drawer-btn.closed {
    left: 0px;
}

.toggle-drawer-btn:hover {
    background: #0056b3;
}

