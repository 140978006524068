body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  /* position: fixed; */
  background-color: #f5f5f5;
  overflow: auto !important ;
}

/* Add this to your CSS file */
.no-scroll {
  overflow: hidden; /* Disable scrolling */
}

/* Ensure the secondary nav fits in one line */
.secondNavContainer .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap; /* Prevent text wrapping */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile */
  padding: 5px 15px;
  font-size: 14px;
}

/* Ensure menu items do not wrap */
.secondNavContainer .navbar-nav {
  display: flex;
  flex-wrap: nowrap; /* Prevents items from wrapping */
  gap: 10px; /* Adds spacing between items */
}

.secondNavContainer .nav-link {
  flex-shrink: 0;
  padding: 6px 10px;
}

variation-btn {
  position: fixed;
  top: 10px; /* Adjust to fit better */
  left: 10px; /* Align to left */
  width: 40px; /* Reduce size */
  height: 40px; /* Keep it square */
  background-color: #4caf50; /* Bootstrap primary blue */
  color: white;
  border: none;
  border-radius: 50%; /* Make it round */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

/* Add hover effect */
.variation-btn:hover {
  transform: scale(1.1);
  background-color: #0056b3; /* Darker blue on hover */
}

/* Brighter font color for sub-menu items in siteNav */
.siteNavBar .navbar-nav .nav-link {
  color: #dddddd; /* Light color for siteNav */
}

.siteNavBar .navbar-nav .nav-link:hover {
  color: #ffffff; /* Brighter on hover for siteNav */
}

/* Darker font color for sub-menu items in secondaryNav */
.secondNavContainer .navbar-nav .nav-link {
  color: #888888; /* Darker color for secondaryNav */
}

.secondNavContainer .navbar-nav .nav-link:hover {
  color: #aaaaaa; /* Slightly brighter on hover for secondaryNav */
}



.secondNavContainer{
  z-index: 1;
}

.dropdown-menu {
  z-index: 9999;
}

.selectionContainer {
  max-height: 50vh; /* Limits container height to 50% of viewport */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  padding: 10px; /* Ensures spacing inside */
  scrollbar-width: thin; /* Keeps scrollbar minimal */
  scrollbar-color: #ccc transparent;
  
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  touch-action: auto; /* Ensures touch gestures work */
}

/* Custom scrollbar styles for Webkit browsers (Chrome, Safari) */
.selectionContainer::-webkit-scrollbar {
  width: 8px;
}

.selectionContainer::-webkit-scrollbar-track {
  background: transparent;
}

.selectionContainer::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 4px;
}

/* Ensures dropdown titles stay visible when scrolling */
.dropdownTitleSection {
  /* position: sticky; */
  top: 0;
  background: white;
  z-index: 1000;
  padding: 10px;
  font-weight: bold;
}


.modal-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.interaction-counts {
  text-align: center;
  margin-top: 10px; /* Adjust as needed */
}

.modal-content {
  padding: 20px;
  font-family: 'Open Sans', sans-serif;
}

.modal-header {
  border-bottom: 1px solid #ddd;
}

.modal-body {
  margin-top: 15px;
}

.modal-body textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 100px;
  resize: vertical;
}

.modal-body .response-area {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
}

button.submit-button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button.submit-button:hover {
  background-color: #003d82;
}

.assistantResponse {
  margin-top: 10px;
}

.avatarTitle{
text-align: center;
}

.hints-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  margin-bottom: 15px; /* Adjust as needed */
}

.hint-box {
  flex: 0 0 98%; /* Adjust width as needed, less than 50% to account for margin/padding */
  padding: 10px;
  margin-bottom: 10px; /* Space between rows */
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 0.9em; /* Smaller text */
  text-align: center; /* Centering text */
}

.hint-box:hover {
  background-color: #f0f0f0;
}

.designer-text {
  color: #f8f9fa;
  font-size: medium;
  padding-left: 4px;
  padding-right: 4px;
}

.designer-icon {
  padding-left: 4px;
}

.modal-body {
  max-height: calc(100vh - 210px); /* Adjust the value based on your modal header and footer height */
  overflow-y: auto;
}

/* assistantModal fin */


.siteName a {
  color: inherit;
  text-decoration: none;
}

.betaForm{
  padding-left: 20px;
}

.legalLinks {
  display: flex;
  justify-content: space-between;
}

.legalLinks a {
  color: #4caf50;
  margin-right: 10px;
}

.Toastify__toast--top-center {
  top: calc(50% - 100px) !important;  
}

.Toastify__progress-bar--warning {
  background: #4caf50 !important;
}

.Toastify__icon-color--warning {
  background: blue !important;
}

:root {
  --toastify-icon-color-warning: blue !important;
}

.without-items .dropdown-toggle::after {
  display: none;
}

.optionsListItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center items horizontally and vertically */
  text-align: center; /* Center the text */
}

/* .optionsListItems .optionInfo,
.optionsListItems .optionImage {
  width: 100%;
}

.optionsListItems img {
  width: 100%;
  height: auto;
} */

/* .optionsListItems span {
  color: white;
} */



.canvas-container {
  margin: 0 auto;
}

.pre-loader {
  /* visibility: hidden; */
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 9999;
  background: url('./images/Preloader.gif') center no-repeat #fff;
}

.siteBackgroundColor {
  background: linear-gradient(135deg, #003366, #00509e);
}

.siteMainBackgroundColor {
  background-color: #d9d9d9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navEnd{
  align-items: center;
}

/* .undoRedo{
  
} */

/* .linkToSite{
  align-items: center;
} */

.button-container {
  display: flex;
  justify-content: space-evenly;  /* Distribute buttons evenly */
  align-items: center;
  width: 100%;
  padding: 5px;  /* Optional: Add spacing at the top */
}

.items {
  display: flex;
  justify-content: space-between;
  width: 100%;  /* Ensure the container stretches to full width */
  flex-wrap: nowrap;  /* Prevent wrapping */
  z-index: 2000;
}

.items button {
  width: 50px;  /* Or a fixed size */
  height: 50px; 
  margin-right: 10px;
}

/* Ensure all buttons have consistent size */
.items .btn {
  
  margin-right: 10px; /* Space between buttons */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;  /* No padding */
}

.itemDetails{
  font-size: x-small;
  padding: 5px;
}
.itemGroup {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  align-items: center; /* Centers buttons vertically */
  gap: 10px; /* Optional: Adds space between buttons */
}

.actionGroup {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  align-items: center; /* Centers the buttons vertically */
  gap: 10px; /* Optional: Adds space between buttons */
  flex-wrap: nowrap; 
}
.items .dropend {
  display: inline-block;  /* Adjust dropend if it's part of the layout */
}

/* Optional: Add spacing between groups of buttons */
.actionGroup, .itemGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


/* .addItemsIcons {
  padding-left: 10px;
  min-height: 43px;
  padding-top: 5px;
  align-items: center;
  padding-bottom: 8px;
} */
.btn.btn-secondary.btn-sm {
  width: 110px;
}
.itemTypeTitle {
  /* background-color: #d9d9d9; */
  font-size: larger;
  padding-right: 3px;
  color: white;
}

.addItemsIcons {
  padding-left: 10px;
  min-height: 30px;
  padding-top: 5px;
  align-items: center;
  padding-bottom: 8px;
}

.dropdownSection {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  /* text-align: center; */
  padding-top: 5px;
  padding-bottom: 10px;
  /* background-color: #0275d8; */
  color: #fff;
  cursor: auto;
}
.dropdownTitleSection {
  display: flex;
  justify-content: center;
  background-color: #4caf50;
  font-size: larger;
  font-weight: 600;
  color: #fff;
  padding-bottom: 6px;
}

.selectionView {
  position: absolute;
  left: 150px;
  z-index: 1;
}

.selectionTitle{

  /* padding-left: 20px; */
  text-align: center;

}


.optionsSelectionView {
  width: 170px;
  max-height: 500px;
  float: left;
  color: white;
  padding-right: 7px;
  padding-left: 10px;
  margin-right: 12px;
  padding-top: 7px;
  border: 1px solid #000;
  background-color: #343a40;
  overflow-y: auto;
}

.infoOption{
  color: white;
}

.optionsListItems {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align items to the right */
}

.optionsListItems span {
  color: white;
}

.noGrid {
  text-align: center;
  color: yellow;
  margin-bottom: 5px;
}

.optionsListItems img {
  align-items: center;
  max-width: 105px;
  padding-bottom: 5px;
  padding-top: 5px;
  /* padding-left: 10px; */
}
.doorsSelectionView {
  width: 160px;
  max-height: 500px;
  float: left;
  color: white;
  padding-right: 7px;
  padding-left: 10px;
  margin-right: 12px;
  padding-top: 7px;
  border: 1px solid #000;
  background-color: #343a40;
  overflow-y: auto;
}
.closeBtn {
  position: sticky;
  top: 0;
  z-index: 10;
  text-align: center;
  background-color: transparent;
}
.openProjectButton {
  text-align: center;
}

.title {
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
  color: #fff;
  padding-bottom: 3px;
  padding-top: 3px;
}
.titleBrand {
  text-align: center;
  font-weight: bolder;
  color: #0275d8;
  padding-bottom: 6px;
  padding-top: 3px;
}
.mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
}
.canvasContainer {
  overflow-y: auto;
  display: flex;
  position: relative;
  max-height: 2400px;
  padding: 5px;
  /* margin-right: 105px; */
}
#canv {
  /* height: 100%; */
  width: 100%;
  /* z-index: 1; */
}
.topBar .undoRedoSmall {
  display: none;
}



/* .upBtn .tipZoomInPopup {
  transform: rotate(270deg);
}
.downBtn .tipZoomInPopup {
  transform: rotate(90deg);
} */
/* .userDropdown .dropdown-menu {
  right: 0;
  left: auto !important;
} */
.beforeEdit,
.afterEdit {
  height: 150px;
  width: 100%;
  border: 1px solid #000;
  margin-bottom: 15px;
}
.mainContainerLeft {
  width: calc(100%);
  float: left;
  padding-left: 10px;
}
.beforeAfter {
  width: 330px;
  float: left;
  padding: 65px 10px 0px 0px;
}
/* Top bar sticky settings */
.topBar {
  position: sticky;
  top: 0px;  /* Sticks to the top within the canvasContainer */
  z-index: 1000;
  display: flex;
  /* padding-right: 10px; */
  justify-content: space-between;
  margin: 10px;
  max-width: 100%;
}

.undoRedoWrapper {
  position: absolute;
  top: 0;  /* Positions the buttons at the top of the screen */
  left: 50%; /* Center the buttons horizontally on the page */
  transform: translateX(-50%); /* Ensure the buttons are exactly centered */
  display: flex;
  justify-content: center;
  z-index: 1000; /* Makes sure the buttons are above other content */
}

.undoRedo {
  display: flex;
  gap: 10px;
}

.siteName{
padding-top: 0px !important;
padding-bottom: 0px !important;
font-size: small;
}
/* feedback form */
.form-button-icon {
  display: none;
  padding-left: 3px;
}

.form-button-text {
  display: inline; /* Show text by default */
}

.brand-and-slogan-container {
  display: flex;
  flex-direction: column;
  font-size: small;
}
.header-container {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  width: 100%;
}

.PrograssBar {
  width: 270px;
  border: 1px solid #ccc;
}
.slogan {
  /* width: 270px; */
  /* border: 1px solid #ccc; */
  /* height: 38px; */
  float: left;
  margin-right: 15px;
  /* margin-top: 14px; */
  font-size: larger;
  font-weight: 600;
  color: #4caf50;
  font-style: italic;

}
.renotrendLink{
  margin-left: auto;
  padding-right: 15px;
}


.baTitle {
  margin: 0;
  text-align: center;
}
.canvasContainerButtons {
  padding-right: 8px;
  /* width: 80px; */
}
/* .items .btn {
  display: block;
  padding: 0px;
  z-index: 1400;
} */

.addItemsButton,
.colorButton,
.optionsButton,
.shapesButton,
.toolsButton,
.selectAllButton,
.removeButton {
    height: 30px;
    /* Adjust the height of the image icon to make it smaller */
    width: auto;
}

.colorButton-eraserDraw {
  background-color: transparent !important;
  border: none !important;
  padding-right: 4px;
  margin-top: 0;
}

.before-after-button {
  position: absolute;
  top: 5px;
  left: 5px;
}

/* .guest-mode-title {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: #4caf50;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  z-index: 1000;
} */

.guestContainer {
  display: flex; /* Enable flexbox for centering */
  align-items: center; /* Vertically centers child elements */
  justify-content: center; /* Horizontally centers child elements */
  z-index: 2;
  background-color: rgba(220, 228, 222, 0.7); /* Light background with slight transparency */
  border: 1px solid #ccc; /* Optional */
  border-radius: 5px; /* Optional */
  padding: 1em;
  margin-left: 1.8em;
  margin-right: 0.3em;
  text-align: center; /* Ensure text is centered inside */
  font-weight: bolder;
}

/* Modal Backdrop */
.detailsModal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.detailsModal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px; /* Wider modal for image and details */
  width: 90%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1010;
  display: flex;
  flex-direction: column;
  position: relative; /* Positioning for close button */
}

/* Modal Content Layout */
.detailsModal-content {
  display: flex;
  flex-direction: row; /* Keep items horizontally aligned */
  gap: 20px; /* Space between image and details */
}

/* Image Section */
.detailsModal-imageContainer {
  flex: 1; /* Take up 1/3 of the modal width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailsModal-imageOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailsModal-image {
  max-width: 100%;
  max-height: 100%; /* Ensure the image fits the container */
  object-fit: contain; /* Maintain aspect ratio */
}

/* Details Section */
.detailsModal-details {
  flex: 2; /* Take up 2/3 of the modal width */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.detailsModal h2 {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.detailsModal-deleteButton {
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 15px;
  cursor: pointer;
}

.detailsModal-deleteButton:hover {
  background-color: darkred;
}


canvas {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: high-quality;
}

.detailsModal-title {
  margin-top: 0;
  margin-bottom: 20px; /* Add spacing below the title */
  font-size: 20px;
  font-weight: bold;
  color: #333;
  text-align: left;
  padding-bottom: 10px;
}

.detailsModal-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.detailsModal-item {
  font-size: 14px;
  margin-bottom: 10px;
}

/* Close Button */
.detailsModal-closeButton {
  position: absolute; /* Place it at the top-right corner */
  top: 10px;
  right: 10px;
  background: transparent;
  color: #333;
  border: none;
  font-size: 20px; /* Size of the X */
  font-weight: bold;
  cursor: pointer;
}

.detailsModal-closeButton:hover {
  color: #007bff; /* Highlight color on hover */
}


.ai-Detect-button-AI {
  position: absolute;
  top: 5px;
  right: 5px;
  /* padding-left: 5px; */
}
.ai-Measurement-button {
  position: absolute;
  color: #fff;
   top: 4%;
  right: 5px;
  /* padding-left: 5px; */
}

/* .PrograssBar .zoom-text {
  background-color: #ccc;
  display: table;
  height: 100%;
  width: 90%;
  background-image: linear-gradient(
    90deg,
    transparent 50%,
    rgba(255, 255, 255, 0.9) 50%
  );
  background-size: 7px;
} */
.actionBtn {
  /* border: 1px solid #343a40; */
  background: transparent;
  /* border-radius: 4px; */
  padding: 5px;
  fill: #343a40;
  margin-bottom: 8px;
  margin-left: 8px;
}
.actionBtn:hover {
  background: #0d6efd;
  fill: #fff;
}
.actionBtn svg {
  width: 20px;
}
.actionBtn.leftBtn svg {
  transform: rotate(-90deg);
}
.actionBtn.rightBtn svg {
  transform: rotate(90deg);
}
.lower-canvas {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}
.upper-canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
}
.canvas-container {
  width: 100% !important;
  height: 100% !important;
  border-radius: 20px;
}
.color-button {
  border: none;
  color: white;
  background-color: #0275d8;
  padding: 8px 24px;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
}

/* .color-button:before {
  content: url(../src/images/svgIcons/paintBucket.svg);
  width: 20px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
} */
.copy-button {
  border: none;
  color: white;
  background-color: #0275d8;
  padding: 8px 24px;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
}

.copy-button:before {
  content: url(../src/images/svgIcons/duplicateSvg.svg);
  width: 20px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
.paste-button {
  border: none;
  color: white;
  background-color: #0275d8;
  padding: 8px 24px;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
}

.paste-button:before {
  content: url(../src/images/svgIcons/pasteSvg.svg);
  width: 20px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
.delete-button {
  border: none;
  color: white;
  background-color: #0275d8;
  padding: 8px 24px;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
}

.delete-button:before {
  content: url(../src/images/svgIcons/deleteSvg.svg);
  width: 20px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
.deleteAll-button {
  border: none;
  color: white;
  background-color: #0275d8;
  padding: 8px 24px;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
}

.deleteAll-button:before {
  content: url(../src/images/svgIcons/deleteAllSvg.svg);
  width: 20px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
.selectAll-button {
  border: none;
  color: white;
  background-color: #0275d8;
  padding: 8px 24px;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
}

.openProjectMobile {
  visibility: hidden;
}

.selectAll-button:before {
  content: url(../src/images/svgIcons/selectAllSvg.svg);
  width: 20px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}

select {
  font-size: 12px !important;  /* Smaller text */
  padding: 5px 8px !important; /* Smaller padding */
  background-color: #444 !important; /* Darker background */
  color: white !important;  /* Ensure contrast */
  border: 1px solid #666 !important;
  border-radius: 4px !important;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.15rem 0.15rem !important;
}

.eraser-draw .btn-lg,
.eraser-draw .btn-group-lg > .btn {
  padding: initial !important;
}

ul {
  list-style: none;
}
.context-menu {
  z-index: 1100;
  position: absolute;
}
.context-menu.hidden {
  display: none;
}
.menu {
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(64 64 64 / 5%);
  padding: 3px 0;
}
.menu > li > a {
  font: inherit;
  border: 0;
  padding: 5px 15px 5px 15px;
  width: 100%;
  display: flex;
  background-color: #fff;
  align-items: center;
  position: relative;
  text-decoration: unset;
  color: #000;
  font-weight: 500;
  transition: 0.5s linear;
  -webkit-transition: 0.5s linear;
  -moz-transition: 0.5s linear;
  -ms-transition: 0.5s linear;
  -o-transition: 0.5s linear;
}
.menu > li > a:hover {
  background: #f1f3f7;
  color: #4b00ff;
}
.menu > li > a > i {
  padding-right: 5px;
}
.menu > li.trash > a:hover {
  color: red;
}

.canvasButtonsContainer {
  display: flex;
  flex-direction: column;
  min-height: 100px;
}

.canvasButonBar {
  padding: 0.3rem;
  margin-top: -4rem;
  z-index: 10;
  max-height: 100px;
}

.customColorName-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100px; /* Or set to the height you prefer */
  width: 100%;
}

.customColorName{
  padding: 3px;
  
}
.canvasAndBannersParent {
  position: relative; /* This ensures that the absolute elements inside it are positioned relative to this container */
  margin-right: 5px;
}

.news-item-body {
  width: 90%; /* Adjust the width as needed */
  margin: auto; /* Centers the content */
  text-align: center; /* Optional: if you want the text to be centered */
}
.news-modal {
  width: 80%; /* Set your desired width */
  max-width: 80%; /* Ensure it doesn't exceed the screen width */
  margin: auto; /* Optional: Center the modal on the screen */
}
.news-item-card {
  width: 300px; /* Slightly less than the modal width */
  margin: auto; /* Center the card within the modal */
}
.custom-colors-section {
 max-height: 200px; /* Adjust the max height as per your design */
  overflow-y: auto; /* Enables vertical scrolling */
  margin-bottom: 10px; /* Optional, for spacing */
  border: 1px solid #ccc; /* Optional, adds a border for clarity */
  padding: 5px; /* Optional, for internal spacing */
}

/* Compact the color picker for mobile */
.sketch-picker-container {
  /* width: 260px !important; */
  height: auto !important;
  max-height: 320px !important;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #222;
  color: #fff;
  min-width: 220px !important;  /* Increase width */
  padding: 10px !important;  /* More padding for spacing */
}
/* Ensure the dropdown section inside the component is wider */
.color-mode-selector select {
  font-size: 12px !important;  /* Smaller text */
  padding: 5px 8px !important; /* Better spacing */
  width: 100% !important; /* Take full width */
  max-width: 90vw !important; /* Ensure it doesn't overflow viewport */
}

.color-mode-selector select {
  max-width: 100%; /* Ensures dropdown doesn't exceed container width */
  min-width: 150px; /* Prevents it from shrinking too much */
}

/* Ensure color swatches take up the full width */
.color-swatch-container,
.custom-color-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center; /* Center align the swatches */
    gap: 6px; /* Space between swatches */
    width: 100%; /* Ensure full width */
    padding-bottom: 8px;
}

/* Adjust individual swatches */
.color-swatch,
.custom-color-swatch {
    width: 30px; /* Fixed size */
    height: 30px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    flex: 0 1 calc(20% - 6px); /* Allow wrapping, each swatch takes up 20% */
    max-width: 48px;
}

/* Scale effect */
.color-swatch:hover,
.custom-color-swatch:hover {
    transform: scale(1.2);
}

/* Custom colors section */
.custom-color-entry {
  /* display: flex; */
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-bottom: 5px;
}

.delete-color-x {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  color: red;
  padding: 2px;
  margin-left: -6px;
}

/* #dropdown-button-dark {
  height: 32px;
  width: 40px;
  padding: 0;
  border-radius: 4px;
} */

.color-title {
  flex-grow: 1;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: x-small;
  /* margin-right: 5px; */
}

.delete-color-x {
  color: white;
  cursor: pointer;
  padding: 0 2px;
  font-size: larger; /* Adjust as needed */
  /* Additional styling for the 'X' */
}