.about-section {
    background-color: #f5f5f5; /* Light grey background */
    padding: 50px 0;
    text-align: center; /* Center-align text */
  }
  
  .about-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333; /* Dark text for contrast */
  }
  
  .about-section p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    max-width: 900px;
    margin: 0 auto; /* Center the content */
  }
  
  .about-details {
    display: flex;
    flex-direction: column; /* Default to vertical on smaller screens */
    gap: 20px;
  }
  
  .about-mission,
  .about-vision {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left; /* Left-align individual sections */
  }
  
  .about-mission h3,
  .about-vision h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .about-mission p,
  .about-vision p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-details {
      flex-direction: column; /* Stack mission and vision sections vertically */
    }
  
    .about-container {
      padding: 0 20px; /* Add horizontal padding on smaller screens */
    }
  }
  