@media (max-width: 768px) {
  .custom-banner, .bannerContainer {
      flex-direction: row; /* Horizontal layout for smaller screens */
      align-items: center; /* Center vertically */
      justify-content: space-between; /* Spread out evenly */
  }
}

.icon {
  width: 20px; 
  height: 40px; 
  margin-right: 10px;
}

.news-banner-container {
  width: 300px;
  height: auto; 
  margin: 0 auto; 
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  padding-left: 20px;
  padding-right: 10px;
  margin: 5px;
}

.news-title {
  color: black;
  font-size: large;
  white-space: nowrap;
  margin: 0;
  padding-top: 10px;
  display: inline-block;
  width: max-content;
  cursor: pointer;
  padding-right: 20px;
}

.news-banner {
  width: 100%; 
}

.customNews-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.news-item {
  padding-top: 10px;
  color: #4CAF50;
  font-weight: bolder;
  display: inline-block;
  width: max-content;
  cursor: pointer;
}

.news-banner.closed {
  display: none;
}

.custom-banner, .bannerContainer {
  width: 100%; 
  height: auto; 
  display: flex;
  flex-direction: row; /* Layout children horizontally */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Distribute items evenly */
  cursor: pointer;
  padding: 0.5rem 1rem; 
  margin-bottom: 5px;
  color: #4CAF50;
  text-align: left; /* Align text to the left */
  flex-shrink: 0;
}

.bannerContainer {
  border-color: black;
  border: 4px solid; /* Ensure border is visible */
}

.bannersContainer {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.news-item::before {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.news-item p {
  margin: 0;
  hyphens: auto;
  word-wrap: break-word;
}

.clientList {
  text-align: center;
}

.vertical-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: left;
  color: rgb(7, 7, 7);
  font-weight: bold;
  font-size: large;
  margin-left: 2px;
}

.horizontal-text {
  color: #4CAF50;
  font-weight: bold;
  text-align: left;
  flex-grow: 1;
  padding-right: 7px;
  line-height: 1.4;
  word-wrap: break-word;
  hyphens: auto;
}

@media (max-width: 768px) {
  .bannersContainer {
    width: 50px;
    z-index: 1;
  }

  .news-title {
    color: black;
    font-size: x-small;
    white-space: nowrap;
    margin: 0;
    padding-top: 6px;
    display: inline-block;
    width: max-content;
    cursor: pointer;
  }

  .news-item {
    padding-top: 10px;
    color: #4CAF50;
    font-weight: bold;
    display: inline-block;
    width: max-content;
    cursor: pointer;
    font-size: x-small;
  }

  .bannersContainer .custom-banner {
    font-size: 10px;
    width: 50%;
  }

  .vertical-text {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-align: left;
    color: rgb(7, 7, 7);
    font-weight: bold;
    font-size: small;
    margin-left: 2px;
  }

  .custom-banner, .bannerContainer {
    width: 15%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-bottom: 5px;
    color: #4CAF50;
    text-align: center;
    flex-shrink: 0;
  }

  .sticky-banner {
    position: fixed; /* Remains in place relative to the viewport */
    bottom: 0;
    width: 100%; /* Ensures it stretches across the screen */
    background-color: #fff; /* Or another appropriate color */
    padding: 10px; /* Spacing within the banner */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
    z-index: 1000; /* Ensure it sits above other content */
}

}
