.selectionComponent {
    position: absolute;
    bottom: 60px; /* Sits just above the MenuComponent */
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    display: flex; /* Enables horizontal layout */
    flex-direction: row; /* Ensures side-by-side display */
    justify-content: center; /* Centers the components */
    align-items: flex-start;
    flex-wrap: nowrap; /* Prevents wrapping */
    gap: 15px; /* Adds space between selection panels */
    z-index: 1100;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    overflow-x: auto; /* Allows horizontal scrolling if needed */
    max-width: 90vw; /* Prevents stretching too wide */
  }
  
  /* Ensure content doesn't wrap */
  .selectionComponent > div {
    flex: 1 1 auto;
    display: flex; /* Ensures each panel follows flex rules */
    justify-content: center;
    align-items: center;
  }
  
  /* Fixed positioning when needed */
  .selectionComponent.fixed {
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* Make sure all selection views align properly */
  .itemSelectionView,
  .optionsSelectionView,
  .transumSelectionView,
  .doorsSelectionView {
    flex: 1;
    min-width: 180px; /* Ensures elements are wide enough for horizontal layout */
    background-color: #343a40;
    color: white;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column; /* Ensures internal content is vertical */
    justify-content: center;
    align-items: center;
  }
  