
.spinner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .spinner-container .spinner {
    margin-left: 20px; /* Adjust this value as needed */
  }

  .floating-button {
    position: absolute;
    top: 25px;
    right: 45px;
    background: #4caf50;
    font-weight: bolder;
    color: rgb(18, 18, 19);
    padding: 5px 10px; /* Reduce padding for text alignment */
    border-radius: 30px; /* Adjust the radius for a sleek button */
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    z-index: 1000; /* Ensure the button is above other content */
    height: 50px; /* Set a fixed height */
    overflow: visible; /* Allow the icon to extend beyond the button */
    padding-left: 80px; /* Add space for the icon */
  }
  
  .floating-button:hover {
    background: #388e3c;
  }
  
  .designer-icon {
    position: absolute; /* Position the icon absolutely */
    left: -10px; /* Move the icon further to the left of the button */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for perfect centering */
    width: 80px; /* Make the icon larger */
    height: 80px;
    border-radius: 50%; /* Make the image circular */
    z-index: 1001; /* Ensure the image appears above the button background */
    border: 3px solid white; /* Add a bold border for emphasis */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for emphasis */
  }
  
  .conversation-history {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .message-container {
    margin-bottom: 10px;
    display: flex;
  }
  
  .user-message {
    justify-content: flex-end;
    text-align: right;
  }
  
  .assistant-message {
    justify-content: flex-start;
    text-align: left;
  }
  
  .user-message-content, .assistant-message-content {
    max-width: 80%;
  }
  
  .message-text {
    padding: 10px;
    border-radius: 10px;
    word-wrap: break-word;
  }
  
  .user-text {
    background-color: #e1f5fe;
    color: #01579b;
    border-radius: 15px 15px 0 15px;
  }
  
  .assistant-text {
    background-color: #eceff1;
    color: #37474f;
    border-radius: 15px 15px 15px 0;
  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  
  
  /* @media (max-width: 400px) {
    .floating-button {
      display: none;
    }
  } */
  @media (max-width: 600px) {
    .floating-button {
      display: flex; /* Ensure the button stays visible */
      position: fixed; /* Keep it fixed on small screens */
      bottom: 20px; /* Move to the bottom for better usability */
      left: 50%; /* Center the button horizontally */
      transform: translateX(-50%); /* Center align */
      background: #4caf50;
      color: white;
      padding: 5px 15px;
      border-radius: 50px;
      cursor: pointer;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 1000; /* Ensure it stays above other content */
      height: auto; /* Let the height adjust dynamically */
      overflow: visible; /* Allow icon to overflow */
    }
  
    .designer-icon {
      width: 60px; /* Adjust size for smaller screens */
      height: 60px;
      margin-right: 10px; /* Keep some spacing between the icon and text */
      transform: none; /* Reset any transform applied for larger screens */
      position: static; /* Ensure it stays inside the button */
      border: 2px solid white;
    }
  
    .floating-button span {
      font-size: 14px; /* Adjust text size for better fit */
    }
  }
  
  