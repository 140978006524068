/* Centered evaluation overlay */
.evaluation-overlay {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    pointer-events: none; /* Allows interaction with the background */
}

/* Evaluation progress container */
.evaluation-box {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    pointer-events: auto;
    max-width: 450px;
    width: 90%;
}

/* Progress bar container */
.progress-container {
    width: 100%;
    height: 8px;
    background-color: #ddd;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 16px;
}

/* Animated progress bar */
.progress-bar {
    height: 100%;
    width: 0%; /* Will be updated dynamically */
    background-color: #4caf50; /* Green progress bar */
    transition: width 0.4s ease-in-out;
}

/* AI Typing Animation */
.typing-animation {
    color: #4caf50;
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
}

.typing-animation span {
    animation: blink 1.4s infinite;
}

.typing-animation span:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-animation span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}
/* Error action buttons */
.error-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 16px;
}

.retry-btn, .cancel-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.retry-btn {
    background-color: #4caf50;
    color: white;
}

.cancel-btn {
    background-color: #f44336;
    color: white;
}

.retry-btn:hover {
    background-color: #45a049;
}

.cancel-btn:hover {
    background-color: #d32f2f;
}
/* Error action buttons */
.error-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 16px;
}

.retry-btn, .cancel-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

