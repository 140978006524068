/* General Styling */
body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

h1, h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700; /* Bold weight for headers */
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400; /* Regular weight for paragraphs */
}

/* Masthead */
.masthead {
  background: linear-gradient(135deg, #003366, #00509e);
  color: white;
  padding: 40px 0;
}

.masthead h1, .masthead h2 {
  color: #ffffff; /* Ensure headers are white */
}

.masthead .text-light {
  color: #FF7F50; /* Accent Orange Color */
}

.masthead .btn-accent {
  background-color: #4caf50; /* Or #ff9800 */
  border-color: #4caf50; /* Or #ff9800 */
  color: #fff;
  transition: background-color 0.3s;
}

.masthead .btn-accent:hover {
  background-color: #45a049; /* Slightly darker green */
}

/* Images and Columns */
.col-md-5 img {
  width: 80%; /* Image occupies 80% of its container's width */
  display: block; /* Makes the image a block element */
  margin: 0 auto; /* Centers the image horizontally */
  max-width: 100%; /* Ensures the image is responsive and doesn't exceed its container */
}

.feature-section .row {
  display: flex;
  justify-content: center; /* Ensure horizontal centering */
  align-items: center; /* Ensure vertical centering */
}

.col-md-6 {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  text-align: center; /* Center text and inline elements like <video> */
}


.img-fluid {
  display: block; /* Required for text-align to work on an image */
  margin-left: auto;
  margin-right: auto;
}

.tryGuest.btn {
  background-color: #4caf50 !important; /* Ensure it gets applied */
  color: white !important; /* Ensure text color is white */
  font-size: large;
}

.tryGuest.btn:hover {
  background-color: #45a049; /* Optional: Add hover effect */
}


/* About Section */
.col-md-7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* ImageSlider.css */
/* Home.css */
.showcase-section {
  margin-bottom: 2rem;
}

.slider-section {
  background-color: #f9f9f9;
}

.showcase-title {
  text-align: center;
  font-size: 2rem;
  color: #4caf50; /* Match with the rest of your design */
  padding-top: 1rem;
}

.home-showcase {
  background-color: #fff;
  color: #6362bb;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  padding: 1rem;
  padding-top: 2rem;
}

.original-image-container, .slider-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.original-image {
  width: 80%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 5rem;
}

.slider-container {
  width: 50%;
}

.image-slider {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.slider-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slider-description {
  font-size: 1rem;
  color: #333;
  margin-top: 1rem;
}


.feature-section h2,
.contact-section h2,
.slider-section h2,
.how-it-works-section h2,
.about-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
}


#about .divider {
  border-color: #fff; /* Divider color */
}

#about .btn-primary {
  background-color: #fff; /* Button background color */
  color: #6362bb; /* Button text color */
}

#about .btn-primary:hover {
  background-color: #fdd835; /* Hover background color */
  color: #fff; /* Hover text color */
}

/* Feature Section */
.feature-section {
  margin: 40px 0;
  text-align: center;
}

/* #howitworks{
  padding-top: 40px;
} */

.how-it-works-section {
  background: linear-gradient(135deg, #003366, #00509e);
}

.how-it-works-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #4caf50; /* Darker text for contrast */
}

.how-it-works-section .row {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.how-it-works-section .card {
  background: white;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.how-it-works-section .card h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

.how-it-works-section .card p {
  font-size: 1rem;
  color: #555;
}

.how-it-works-section .card a {
  color: #007bff; /* Subtle blue for links */
  text-decoration: none;
  font-weight: bold;
}

.how-it-works-section .card a:hover {
  text-decoration: underline;
}

.how-it-works-section .icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #4caf50; /* Green icon for consistency */
}



.bg-light {
  background-color: #f8f9fa !important;
}

.feature-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.feature-video {
  width: 80%; /* or whatever width you prefer */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Center horizontally */
  display: block; /* Ensures the video behaves as a block-level element for margin auto to work */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-section .col-md-6 {
  padding: 20px;
}

.feature-section .img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-section .steps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.feature-section .step {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 45%;
}

.feature-section .step-icon {
  font-size: 2em;
  margin-right: 15px;
  color: #007bff;
}

.feature-section .step-content {
  flex: 1;
}

.feature-section .step-content .step-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.feature-section .step-content .step-description {
  font-size: 1em;
  color: #555;
}

.feature-section .step-content .step-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.feature-video {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* General Section Styling */
/* Adjust Image Sizes */
.ai-feature-section img {
  max-width: 80%; /* Scale down image width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Add spacing below each image */
  border-radius: 8px; /* Add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.cta-container {
  margin-top: 10px; /* Adjust as needed */
  margin-bottom: 10px; /* Adjust as needed */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ai-feature-section img {
    max-width: 90%; /* Further reduce size for smaller screens */
  }
}

/* Text Positioning */
.ai-feature-section .text-primary {
  font-size: 1.2rem;
  margin-top: 10px;
}

.ai-feature-section p {
  font-size: 0.95rem;
  line-height: 1.5;
  padding: 0 10px; /* Add padding for better readability */
}

/* Ensure Proper Text Visibility */
.ai-feature-section h5 {
  font-size: 1.1rem; /* Adjust title font size */
  margin-bottom: 10px;
  color: #333; /* Ensure legibility */
}
.ai-feature-section h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center; /* Ensure the title is centered */
  margin-bottom: 10px; /* Space below the title */
}

.ai-feature-section p.subtitle {
  text-align: center; /* Center-align the subtitle */
  font-size: 1.2rem; /* Adjust font size for better readability */
  color: #555; /* Use a soft gray for contrast */
  margin: 0 auto; /* Center in the container */
  max-width: 80%; /* Restrict the width for smaller screens */
}


.ai-feature-section p {
  color: #555; /* Subtle gray for secondary text */
}

.ai-feature-section .row {
  display: flex;
  justify-content: space-between; /* Ensure spacing between items */
  align-items: flex-start; /* Align all items at the top */
}

.ai-feature-section .col-lg-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ai-feature-section .col-lg-3 img {
  margin-bottom: 15px; /* Add consistent spacing below images */
}




/* Responsive styling */
@media (max-width: 767px) {
  .feature-section .step {
    flex: 1 1 100%;
  }
}

.step {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Space between steps */
}

.step-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px; /* Space between title and description */
  color: #333;
}

.step-description {
  font-size: 1em;
  color: #555;
}
/* Add this to home.css or a similar CSS file */
.welcome-video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.welcome-video-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  text-align: center;
}


.skip-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .home-showcase {
    display: flex;
    flex-direction: column; /* Stack the images vertically */
    align-items: center; /* Center align images and content */
    margin: 2rem 0;
    padding: 1rem;
  }

  .original-image-container, .slider-container {
    width: 100%; /* Make both containers occupy full width */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem; /* Add spacing between stacked sections */
  }

  .original-image {
    width: 90%; /* Adjust the width for better visibility */
    margin-bottom: 1rem; /* Add spacing below the original image */
  }

  .slider-container {
    margin-bottom: 1rem; /* Add spacing below the slider */
  }

  .slider-description {
    text-align: center; /* Center-align the description text */
    margin-top: 1rem; /* Add spacing above the text */
    font-size: 1rem; /* Adjust the font size */
  }
}
#howitworks {
  text-align: center; /* Center-align the text */
  padding: 2rem 1rem;
  background-color: #f9f9f9; /* Optional: Add a background color for contrast */
}

#howitworks h1 {
  font-size: 1.8rem; /* Adjust size for better visibility */
  color: #4caf50; /* Match your theme color */
  cursor: pointer; /* Make it clear the title is clickable */
  transition: color 0.3s ease; /* Add a hover effect */
}

#howitworks h1:hover {
  color: #388e3c; /* Slightly darker shade on hover */
}

@media (max-width: 767px) {
  #howitworks {
    text-align: center; /* Center everything on small screens */
    padding: 1.5rem 1rem; /* Reduce padding for smaller devices */
  }

  #howitworks h1 {
    font-size: 1.5rem; /* Smaller font size for mobile */
  }

  .feature-video {
    width: 100%; /* Ensure the video fits the screen */
    max-width: 100%; /* Prevent overflow */
    margin: 0 auto; /* Center-align the video */
  }
}



