.custom-toggle {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

  .manufacturerDropdown .dropdown-toggle {
    width: fit-content;
    margin: 0 auto;
  }

  .custom-toggle {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .closeBtn{
    padding-bottom: 10px;
    padding-top: 5px;
  }

  .optionsTitle {
    display: flex;
    justify-content: center;
    /* align-items: center;  */
  }

  /* .optionsListItems{
    background-color: white;
  } */

  .btn-primary {
    background-color: #4caf50; /* Or #ff9800 */
    
    border-color: #4caf50; /* Or #ff9800 */
  }
  