.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.modal{
  z-index: 1800;
}

@media (max-width: 768px) {
  .defaultImages img {
     height: 60px;
  }
  
  .thumbnail {
     width: 100px;
  }
}

.mt-20 {
  margin-top: 20px;
}

h3, h4 {
  font-weight: 400;  
}

.btn:hover {
  filter: brightness(0.9);
}

.btn:active {
  filter: brightness(0.8);
}


.titleNewProject,
.projectNameInput,
.uploadedImage,
.text-center,
.error,
.weightAndType {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}


.uploadProgress {
  width: 200px;
  background-color: dimgray;
  margin-top: 20px;
}

.openProjectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.faContainer {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
}

.progressContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}


.titleNewProject {
  margin-top: 20px;
}

.projectNameInput {
  margin-top: 20px;
}

.uploadedImage {
  margin-top: 20px;
  text-align: center;
}

.defaultImagesSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.defaultImages {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.defaultImages img {
  height: 100px;
  cursor: pointer;
  margin: 0 10px; /* This adds spacing between the images */
}

.defaultImages img:hover {
  transform: scale(1.05); 
  transition: transform 0.3s ease-in-out;
}


.text-center {
  margin-top: 20px;
}

.error {
  margin-top: 20px;
  color: red;
  text-align: center;
  border: 1px solid red;
  padding: 5px 10px;
  border-radius: 5px;
}

.weightAndType {
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.thumbnailContainer {
  text-align: center;
  margin-top: 20px;
}

.thumbnail {
  width: 150px;
  height: auto;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 5px;
}

body .Toastify__toast--top-center {
  top: calc(50% - 60px);  
}

.tooltip-container {
  position: relative;
  display: inline-block;
  text-align: center;
}

.arrow {
  position: absolute;
  top: -20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  color: #000;
  animation: moveArrow 2s infinite; /* Slowed down the animation */
}

@keyframes moveArrow {
  0% {
    transform: translateX(-50%) translateY(-10px);
    opacity: 0.7;
  }
  50% {
    transform: translateX(-50%) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-10px);
    opacity: 0.7;
  }
}

.faContainer {
  cursor: pointer;
}

/* Place the AI toggle button at the top */
.ai-toggle-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px 12px;
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Toggle switch style */
.ai-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ai-toggle input {
  margin-right: 8px;
  cursor: pointer;
}

.image-name {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-top: 5px; /* Reduced spacing */
}

.thumbnail {
  width: 150px; /* Ensure consistent image size */
  height: auto;
  max-width: 100%; /* Prevents overflow */
  object-fit: contain; /* Keeps aspect ratio */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 5px;
}

.defaultImages img {
  height: 100px; /* Restore original size */
  width: auto;
  max-width: 100%;
}

