/* Ensure modal is tall enough to fit all sliders */
.zoom-pan-modal {
  max-width: 70%;
  min-height: 220px; /* Ensures enough space for sliders */
  position: absolute;
  top: auto;
  /* right: 10%; */
  transform: none;
  z-index: 2000;
}

/* Ensure sliders are in a row and evenly spaced */
.slider-container {
  display: flex;
  justify-content: space-between; /* Distributes space evenly */
  align-items: center;
  flex-wrap: wrap; /* Ensures it works on small screens */
  gap: 10px; /* Adds spacing */
  padding: 10px;
}

/* Adjust slider width to take up more space */
.movement-x-slider,
.movement-y-slider,
.zoom-slider {
  display: block;
  width: 90px; /* Increase width for better touch control */
  margin: 5px auto;
}

/* Labels should be centered under sliders */
.slider-label,
.slider-label-x {
  text-align: center;
  font-size: small;
}

/* Buttons should be in one row */
.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

/* Close & Reset buttons */
.custom-button {
  min-width: 50px;
}

/* Ensure everything remains in a row on mobile */
@media (max-width: 768px) {
  .zoom-pan-modal {
    max-width: 90%;
    min-height: 250px; /* More space for touch interaction */
  }

  .slider-container {
    flex-direction: row;
    justify-content: center;
    gap: 15px;
  }

  .movement-x-slider,
  .movement-y-slider,
  .zoom-slider {
    width: 80px; /* Slightly smaller but still touch-friendly */
  }
}
