.faq-section .faq-item.active {
    background: #8bc34a; /* Lighter green for a softer appearance */
    color: white;
    border: 1px solid #8bc34a;
  }

  .faq-container {
    background-color: #f5f5f5;
    padding: 50px 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .faq-accordion {
    margin-top: 20px;
  }
  
  .faq-item {
    background-color: white;
    padding: 15px 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .faq-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* .faq-item.active {
    background-color: #4caf50;
    color: white;
  } */
  
  .faq-question {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .faq-answer {
    margin-top: 10px;
    font-size: 1rem;
    color: #333;
  }
  
  .faq-image-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .faq-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .faq-caption {
    font-size: 0.9rem;
    color: #777;
    margin-top: 5px;
  }
  