.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
  }
  
  .spinner > div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: #ccc;
    animation: spinner-animation 1.4s infinite ease-in-out both;
  }
  
  .spinner .dot1 {
    animation-delay: -0.32s;
  }
  
  .spinner .dot2 {
    animation-delay: -0.16s;
  }
  
  @keyframes spinner-animation {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
  
    40% {
      transform: scale(1);
    }
  }
  
  .spinner .dot1,
  .spinner .dot2 {
    background-color: #007bff;
  }

.closeBtn {
    position: sticky;
    top: 0;
    background-color: white; /* Ensure contrast */
    z-index: 1000;
    padding: 10px;
    text-align: right;
}


  .optionsTitle {
    display: flex;
    justify-content: center;
    /* align-items: center;  */
  }
 /* Ensure the selection list doesn't take too much space */
.selectionListItems {
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
  .selectionListItems .list-group-item {
    display: inline-block; /* Prevents vertical stacking */
    flex: 0 0 auto; /* Prevents items from stretching */
    text-align: center;
  }
  
  .list {
    max-height: 60vh; /* Limit the height to 60% of the viewport */
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin; /* Ensure a thin scrollbar for a clean look */
    scrollbar-color: #ccc transparent; /* Custom scrollbar color */
}

/* For Webkit browsers */
.list::-webkit-scrollbar {
    width: 8px;
}

.list::-webkit-scrollbar-track {
    background: transparent;
}

.list::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 4px;
}
  